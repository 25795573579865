import React, { MouseEvent, useState, useContext } from "react";
import { useFormik, FormikErrors } from "formik";
import { navigate } from "gatsby";

import formValidation from "~/helpers/formValidation";
import Button from "~/components/button";
import Heading from "~/components/Typography/Heading";
import { Form } from "~/components/forms/form";
import { InputField } from "~/components/Inputs/InputField";
import { MainContext } from '~/context'

interface validateValues {
  email?: string;
  newEmail?: string;
  password?: string;
  newPassword?: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  schoolDistrict?: string;
  country?: string;
}


const validate = (values: validateValues) => {
  const errors: FormikErrors<{
    email: any,
    newEmail: string,
    password: string,
    newPassword: string,
    firstName: any,
    lastName: any,
    role: any,
    schoolDistrict: any,
    country: any
  }> = {};

  const emailValidation = formValidation("email");
  const matchValidation = formValidation("match");

  if (values.newEmail) {
    errors.newEmail = emailValidation(values.newEmail);
  }

  if (values.password || values.newPassword) {
    errors.password = matchValidation(values.password, values.newPassword);
    errors.newPassword = matchValidation(values.newPassword, values.password);
  }
  return errors;
};

const AccountDetailsForm = (): JSX.Element | null => {
  const { updateUser, getUser, user } = useContext(MainContext);

  if (!user) return null;

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: user?.email || "",
      newEmail: "",
      password: "",
      newPassword: "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      role: user?.role || "",
      schoolDistrict: user?.schoolDistrict || "",
      country: user?.country || "",
    },
  });


  const handleRedirect = () => {
    navigate("/dashboard");
  };

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    try {
      e.preventDefault();
      const errors: FormikErrors<{
        email: any,
        newEmail: string,
        password: string,
        newPassword: string,
        firstName: any,
        lastName: any,
        role: any,
        schoolDistrict: any,
        country: any
      }> = validate(formik.values);

      formik.setErrors(errors);

      if (Object.values(errors).some((err) => err !== null)) return;

      const {
        email,
        newEmail,
        password,
        newPassword,
        firstName,
        lastName,
        role,
        schoolDistrict,
        country,
      } = formik.values

      setLoading(true)

      await updateUser({
        email: newEmail ? newEmail : email,
        password:
          !!password && password === newPassword
            ? password
            : null,
        firstName,
        lastName,
        role,
        schoolName: "",
        schoolDistrict,
        schoolEmail: "",
        country,
        state: ""
      })

      await getUser()
      setLoading(false)
      navigate('/dashboard')

    } catch (error) {
      setLoading(false)
      return console.error(error.message);
    }
  };

  const handleChange = (event: { target: HTMLInputElement }) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  return (
    <>
      <Form>
        <Heading underline color="ms" level={2} isBigger>
          Account Information
        </Heading>
        <div>
          <InputField
            className="mt-5"
            disabled={formik.values.email}
            label="Current E-mail Address"
            name="email"
            onChange={handleChange}
            placeholder={""}
            value={formik.values.email}
          />
          <InputField
            className="mt-8"
            error={formik.errors.newEmail}
            label="New E-mail Address"
            name="newEmail"
            onChange={handleChange}
            value={formik.values.newEmail}
          />
          <InputField
            className="mt-8"
            error={formik.errors.password}
            label="New Password"
            name="password"
            onChange={handleChange}
            type="password"
            value={formik.values.password}
          >
            <p className="text-blue text-tn max-w-sm">
              To change password or e-mail please insert your current password.
              After that click save. If you change your email -we will send
              confirmation link to the current email in our system.
            </p>
          </InputField>
          <InputField
            className="mt-8"
            error={formik.errors.newPassword}
            label="Repeat new Password"
            name="newPassword"
            onChange={handleChange}
            type="password"
            value={formik.values.newPassword}
          />
        </div>
        <Heading underline level={2} color="ms" isBigger>
          Teacher Information
        </Heading>
        <div>
          <InputField
            className="mt-5"
            label="First Name"
            name="firstName"
            onChange={handleChange}
            placeholder={""}
            type="firstName"
            value={formik.values.firstName}
          />
          <InputField
            className="mt-8"
            label="Last Name"
            name="lastName"
            onChange={handleChange}
            placeholder={""}
            value={formik.values.lastName}
          />
          <InputField
            className="mt-8"
            label="Professional Role"
            name="role"
            onChange={handleChange}
            placeholder={""}
            value={formik.values.role}
          />
          <InputField
            className="mt-8"
            label="School District / University Affiliation"
            name="schoolDistrict"
            onChange={handleChange}
            placeholder={""}
            value={formik.values.schoolDistrict}
          />
          <InputField
            className="mt-8"
            label="Country"
            name="country"
            onChange={handleChange}
            placeholder={""}
            value={formik.values.country}
          />
        </div>
        <div className="flex flex-wrap sm:flex-no-wrap w-full max-w-md mt-10">
          <Button
            action="secondary"
            color="purple"
            onClick={handleRedirect}
            style={{ minWidth: "152px" }}
            title="Cancel"
          >
            Cancel
          </Button>
          <Button
            action="primary"
            className="sm:ml-auto mt-6 lg:mt-0"
            color="purple"
            loading={loading}
            onClick={handleSubmit}
            style={{ minWidth: "152px" }}
            title="Save"
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );

};

export default AccountDetailsForm;
