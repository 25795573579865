import React, { RefObject, useContext, useEffect, useRef, useState } from "react";
import { navigate } from "gatsby";

import AccountLayout from "~/layouts/account";
import AccountDetailsForm from "~/components/forms/account-details-form";
import Button from "~/components/button";
import { DeleteAccountModal } from "~/components/modals";
import { MainContext } from "~/context";
import { remove } from "~/helpers/api";
import useAuth from "~/hooks/useAuth";

const AccountProfile = () => {
  const { user, getUser } = useContext(MainContext);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const deleteAccountRef: RefObject<HTMLButtonElement> = useRef(null);

  const authenticated: boolean = useAuth("/sign-in");

  const toggleModalVisibility = () => setModalVisible(!modalVisible);

  const onDeleteHandler = async () => {
    setLoading(true);

    try {
      await remove("/deleteAccount", { data: { id: user._id }});
      await getUser();

      localStorage.removeItem("user");
      setLoading(false);
      navigate("/sign-in");
    }
    catch(error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modalVisible || !deleteAccountRef?.current) return;

    deleteAccountRef.current.focus();
  }, [modalVisible])

  if (!authenticated) {
    return <div>...loading</div>;
  }

  return (
    <AccountLayout activeLink="/account" >
      <AccountDetailsForm />
      <div className="w-11/12 h-px my-10 bg-gray-200"/>
      <div className="max-w-md">
        <div className="flex items-center mb-2">
          <i className="fas fa-exclamation-circle text-red-700 text-2xl" />
          <p className="text-2xl text-red-700 font-bold ml-3">Danger Zone</p>
        </div>
        <p className="text-lg text-gray-600">All of your classes and associated data will be lost.</p>
      </div>
      <div className="flex w-full max-w-md justify-end mt-4">
        <Button
          action="primary"
          color="red"
          onClick={toggleModalVisibility}
          ref={deleteAccountRef}
          title="Delete account"
        >
          Delete account
        </Button>
      </div>
      {modalVisible && (
        <div className="fixed inset-0 w-full h-screen">
          <DeleteAccountModal
            toggleVisibility={toggleModalVisibility}
            onClick={onDeleteHandler}
            loading={loading}
          />
        </div>
      )}
    </AccountLayout>
  );
};

export default AccountProfile;
